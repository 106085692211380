import axiosInstance from "../helpers/axiosInstance";

export const connectionsList = (data = {}) => {
  return axiosInstance.get("/Admin/connectionList", { params: data });
};

export const getConnectionSelectList = (data) => {
  return axiosInstance.get("/Admin/connectionList", { params: data });
};

export const getConnection = (data = {}) => {
  return axiosInstance.get("/Admin/connection", { params: data });
};

export const addConnection = (data) => {
  return axiosInstance.post("/Admin/connection", data);
};

export const updateConnection = (data) => {
  return axiosInstance.post("/Admin/connection", data);
};

export const deleteConnection = (id) => {
  return axiosInstance.delete("/Admin/connection", { params: { id } });
};

const ConnectionService = {
  connectionsList,
  getConnection,
  addConnection,
  updateConnection,
  deleteConnection,
  getConnectionSelectList,
};

export default ConnectionService;
