import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ForgetPassword from "./admin/authentication/ForgetPassword";
import Login from "./admin/authentication/Login";
import AuthLayout from "./admin/layout/AuthLayout";
import MainLayout from "./admin/layout/MainLayout";
import { SpinnerLoader, SuspenseLoader } from "./components/loader";
import Toast from "./components/toasts/Toast";
import "./i18n";

import "./App.css";
import { useSelector } from "react-redux";
import ErrorBoundry from "./components/ErrorBoundry";
import { QueryClient, QueryClientProvider } from "react-query";
import { ConfigProvider } from "antd";
import EditModel from "./admin/tvDashboard/EditModel";
import { setToken } from "./helpers/myStorage";

const Dashboard = lazy(() => import("./admin/Dashboard"));
const Account = lazy(() => import("./admin/account/Account"));
const ConnectionEdit = lazy(() => import("./admin/connection/ConnectionEdit"));

const queryClient = new QueryClient();

function App() {
  const { i18n } = useTranslation();
  const { language } = useSelector((state) => state.language);
  const { mode } = useSelector((state) => state.theme);

  useEffect(() => {
    i18n.changeLanguage(language);
    // eslint-disable-next-line
  }, [language]);
  useEffect(() => {
    const handleMessage = (event) => {
      if (!event.data.parentToken) return;
      setToken(event.data.parentToken);
    };
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            colorBgElevated: mode === "dark" ? "rgb(51 51 51 / 1)" : "white",
            colorTextHeading: mode === "dark" ? "white" : "black",
          },
          Tree: {
            colorBgContainer:
              mode === "dark" && "rgb(51 51 51 / var(--tw-bg-opacity))",
            colorIcon: mode === "dark" && "white",
          },
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <ErrorBoundry>
          <React.StrictMode>
            <Suspense fallback={<SuspenseLoader />}>
              <SpinnerLoader />
              <Router>
                <Routes>
                  <Route path="/connection" element={<ConnectionEdit />} />
                  <Route path="/authentication" element={<AuthLayout />}>
                    <Route index element={<Login />} />
                    <Route
                      path="forget-password"
                      element={<ForgetPassword />}
                    />
                  </Route>
                  <Route path="/" element={<MainLayout />}>
                    <Route index element={<Dashboard />} />
                    <Route path="/profile" element={<Account />} />
                    <Route path="/tvDashboard/:code" element={<EditModel />} />
                  </Route>
                </Routes>
              </Router>
              <Toast />
            </Suspense>
          </React.StrictMode>
        </ErrorBoundry>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
