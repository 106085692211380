export const filterArrayOfNestedObject = (
  arr,
  searchValue,
  nestedKey,
  lookupKey,
  isRegex = false,
) => {
  return arr.filter((o) => {
    const keep = o[lookupKey] === searchValue;

    if (keep && o[nestedKey]) {
      o[nestedKey] = filterArrayOfNestedObject(
        o[nestedKey],
        searchValue,
        nestedKey,
        lookupKey,
      );
    }

    return keep;
  });
};

/**
 * Filter array and returned new array
 *
 * @param {Array} arr
 * @param {String} searchValue
 * @param {String} nestedKey
 * @param {String} lookupKey
 * @returns {Array}
 */
export const filterArrayOfNestedObjectRegex = (
  arr,
  searchValue,
  nestedKey,
  lookupKey,
) => {
  const regexp = new RegExp(`^${searchValue}`, "i");
  return arr.filter((o) => {
    const keep = regexp.test(o[lookupKey]) === true;

    if (keep && o[nestedKey]) {
      o[nestedKey] = filterArrayOfNestedObject(
        o[nestedKey],
        searchValue,
        nestedKey,
        lookupKey,
      );
    }

    return keep;
  });
};

/**
 * Filter an array nestedly
 *
 * @param {Array} array
 * @param {Func} fn
 * @param {string} nestedKey
 * @returns
 */
export const filterNestedArray = (array, fn, nestedKey) => {
  return array.reduce((r, o) => {
    var children = filterNestedArray(o[nestedKey] || [], fn, nestedKey);
    if (fn(o) || children.length)
      r.push(Object.assign({}, o, children.length && { children }));
    return r;
  }, []);
};

/**
 * Convert Date to timestamp or in seconds
 *
 * @param {string} str
 * @param {bool} isSecond
 * @returns {int}
 */
export const dateToTime = (str, isSecond = false) => {
  const date = new Date(str);
  const timestamp = date.getTime();
  if (isSecond) {
    return Math.floor(timestamp / 1000);
  }
  return timestamp;
};

/**
 * Capitalize first letter of any string
 *
 * @param {string} string
 * @returns {string}
 */
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Check and remove field error and return new object
 *
 * @param {Object} errors
 * @param {String} field
 * @returns
 */
export const handleError = (errors, field) => {
  let newError = {};
  let keys = Object.keys(errors);
  keys.forEach((key) => {
    if (key !== field) {
      newError[key] = errors[key];
    }
  });

  return newError;
};

export const filterArrayOfElements = (arr, filter, key, search) => {
  let a = { ...arr[0], [key]: [] };
  return arr.map((element) => {
    for (let i = 0; i < element[key].length; i++) {
      if (element[key][i].title.toUpperCase().includes(search.toUpperCase())) {
        a[key].push(element[key][i]);
      }
    }
    return a;
  });
};

// Create a random ID
export const generateUEID = () => {
  let first = (Math.random() * 46656) | 0;
  let second = (Math.random() * 46656) | 0;
  first = ("000" + first.toString(36)).slice(-3);
  second = ("000" + second.toString(36)).slice(-3);

  return first + second;
};
