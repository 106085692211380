import React, { useState } from "react";
import Search from "../../components/Search";
import { NavList } from "../../components/menus";
import { filterArrayOfElements } from "../../helpers/utitity";
import navigationList from "../../data/navigation";
import { useTranslation } from "react-i18next";

const Navigation = () => {
  const [search, setSearch] = useState("");
  const { t } = useTranslation();
  const getFilter = () => {
    if (!search) {
      return navigationList;
    }
    const regexp = new RegExp(`^${search}`, "i");
    const filter = ({ title }) => regexp.test(t(title)) === true;
    return filterArrayOfElements(navigationList, filter, "childs", search);
  };
  return (
    <>
      <div className="block m-1">
        <Search onSearch={setSearch} />
      </div>
      <NavList
        navList={getFilter()}
        className="w-full float-none flex flex-col font-medium ltr:pl-1.5 rtl:pr-1.5"
      />
    </>
  );
};

export default Navigation;
