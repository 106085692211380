import React from "react";
import PropTypes from "prop-types";
import InputError from "../InputError";

const WhiteInputText = ({ onChange, error, ...p }) => {
  return (
    <div className="w-1/2 ">
      <input
        {...p}
        className="leading-5 relative py-1.5 px-4 text-gray-800 bg-gray-100 border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-900 dark:opacity-100 dark:bg-white dark:border-white dark:focus:border-white"
        onChange={onChange}
        autoComplete="off"
      />
      <InputError>{error}</InputError>
    </div>
  );
};

WhiteInputText.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default WhiteInputText;
