import UserIcon from "../icons-svg/UserIcon";
import UserSearchIcon from "../icons-svg/UserSearchIcon";
import ConnectionSearchIcon from "../icons-svg/ConnectionSearchIcon";
import ConnectionIcon from "../icons-svg/ConnectionIcon";
// import { BsFillEnvelopeFill } from "react-icons/bs";

const navData = [
  {
    id: 1,
    title: "Users",
    icon: (
      <UserIcon className="inline-block h-4 w-4 ltr:mr-2 rtl:ml-2 align-baseline text-[#069AFF]" />
    ),
    tabIcon: (
      <UserIcon className="inline-block h-4 w-4 ltr:mr-2 rtl:ml-2 align-baseline" />
    ),
    childs: [
      {
        id: 11,
        title: "Users Search",
        component: "admin/user/User",
        componentProps: {},
        icon: (
          <UserSearchIcon className="inline-block h-4 w-4 ltr:mr-2 rtl:ml-2 align-baseline text-[#6F3EFF]" />
        ),
        tabIcon: (
          <UserIcon className="inline-block h-4 w-4 ltr:mr-2 rtl:ml-2 align-baseline" />
        ),
      },
      {
        id: 12,
        title: "Connection Search",
        component: "admin/connection/ConnectionSearch",
        componentProps: {},
        icon: (
          <ConnectionSearchIcon className="inline-block h-4 w-4 ltr:mr-2 rtl:ml-2 align-baseline text-[#FFE10B]" />
        ),
        tabIcon: (
          <ConnectionSearchIcon className="inline-block h-4 w-4 ltr:mr-2 rtl:ml-2 align-baseline" />
        ),
      },

      // {
      //   id: 14,
      //   title: "Mail Accounts",
      //   component: "admin/mail/MailAccounts",
      //   componentProps: {},
      //   icon: (
      //     <BsFillEnvelopeFill className="inline-block h-4 w-4 ltr:mr-2 rtl:ml-2 align-baseline text-orange-500" />
      //   ),
      //   tabIcon: (
      //     <ConnectionSearchIcon className="inline-block h-4 w-4 ltr:mr-2 rtl:ml-2 align-baseline" />
      //   ),
      // },
    ],
  },
  // {
  //   id: 2,
  //   title: "Dashboard",
  //   icon: (
  //     <UserIcon className="inline-block h-4 w-4 ltr:mr-2 rtl:ml-2 align-baseline text-[#069AFF]" />
  //   ),
  //   tabIcon: (
  //     <UserIcon className="inline-block h-4 w-4 ltr:mr-2 rtl:ml-2 align-baseline" />
  //   ),
  //   childs: [
  //     {
  //       id: 21,
  //       title: "Model Search",
  //       componentProps: {},
  //       icon: (
  //         <ConnectionSearchIcon className="inline-block h-4 w-4 ltr:mr-2 rtl:ml-2 align-baseline text-[#FFE10B]" />
  //       ),

  //       component: "admin/tvDashboard/TvDashboard",
  //     },
  //     {
  //       id: 22,
  //       title: "Device Search",
  //       componentProps: {},
  //       icon: (
  //         <ConnectionSearchIcon className="inline-block h-4 w-4 ltr:mr-2 rtl:ml-2 align-baseline text-blue-800" />
  //       ),
  //       component: "admin/device/DeviceSearch",
  //     },
  //   ],
  // },
];

export const additionalTabs = [
  {
    title: "Connection",
    tabIcon: (
      <ConnectionIcon className="inline-block h-4 w-4 ltr:mr-2 rtl:ml-2 align-baseline" />
    ),
  },
  {
    title: "User",
    tabIcon: (
      <UserIcon className="inline-block h-4 w-4 ltr:mr-2 rtl:ml-2 align-baseline" />
    ),
  },
  {
    title: "Edit",
    tabIcon: (
      <UserIcon className="inline-block h-4 w-4 ltr:mr-2 rtl:ml-2 align-baseline" />
    ),
  },
];

export default navData;
