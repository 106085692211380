import { axiosInstanceForTvDashboard } from "../helpers/axiosInstance";
import * as MyStorage from "../helpers/myStorage";

export const getDeviceList = (data = {}) => {
  return axiosInstanceForTvDashboard
    .get(MyStorage.getLinkForTvDashboard() + "/admin/deviceList", {
      params: data,
    })
    .then((res) => {
      return res;
    });
};

export const deleteDevice = (uuid) => {
  return axiosInstanceForTvDashboard
    .delete(MyStorage.getLinkForTvDashboard() + "/admin/device", {
      params: { uuid },
    })
    .then((res) => {
      return res;
    });
};

export const postDevice = (data = {}) => {
  return axiosInstanceForTvDashboard
    .post(MyStorage.getLinkForTvDashboard() + "/admin/device", data)
    .then((res) => {
      return res;
    });
};

const DeviceService = {
  getDeviceList,
  deleteDevice,
  postDevice,
};

export default DeviceService;
